<template>
  <div>
    <div>
      <Card>
        <p slot="title" class="card-title">
          基本信息
        </p>
        <div class="home-basic">
          <div class="home-basic-left">
            <div class="home-basic-left-img">
              <img :src="avatar">
            </div>
            <div class="home-basic-left-name">
              {{ userInfo.name }}
            </div>
          </div>
          <div class="home-basic-right">
            <div class="home-basic-right-chart">
              <div class="home-basic-right-chart-img">
                <img :src="chartUrl">
              </div>
              <div class="home-basic-right-chart-cnt">
                <span style="font-size: 30px">{{ recognitionCount }}</span>
                <span style="margin-left: 10px">次</span>
              </div>
            </div>
            <div class="home-basic-right-label">总识别次数</div>
          </div>
        </div>
      </Card>
    </div>
    <div>
      <Card style="margin-top: 10px" v-for="(item,index) in classReportList" :key="index">
        <div slot="title" class="card-title2">
          <div>{{ item.className }}-测评报告</div>
          <div>
              <DatePicker v-model="time" type="datetimerange" placeholder="请选择起止时间" @on-change="queryEvaluationReport"
                          style="width: 320px"></DatePicker>
          </div>
        </div>
        <div class="analyse-box-main">
          <div class="analyse-box-learn">
            <div class="analyse-box-learn-up">
              <div class="analyse-box-learn-up-item">
                <div class="analyse-box-learn-up-item-img img-1"></div>
                <div class="analyse-box-learn-up-item-info">
                  <div class="item-info-count item-info-color1">{{ item.evaluationTimes }}</div>
                  <div class="item-info-label">测评次数</div>
                </div>
              </div>
              <div class="analyse-box-learn-up-item">
                <div class="analyse-box-learn-up-item-img img-2"></div>
                <div class="analyse-box-learn-up-item-info">
                  <div class="item-info-count item-info-color2">{{ item.comprehensiveScore }}</div>
                  <div class="item-info-label">综合得分</div>
                </div>
              </div>
              <div class="analyse-box-learn-up-item">
                <div class="analyse-box-learn-up-item-img img-3"></div>
                <div class="analyse-box-learn-up-item-info">
                  <div class="item-info-count item-info-color3">{{ item.comprehensiveRecognitionRate }}%</div>
                  <div class="item-info-label">综合识别率</div>
                </div>
              </div>
            </div>
            <div :id="'learnMain'+index" style="height: 400px;"></div>
          </div>
        </div>
      </Card>
    </div>
    <!--    <Button @click="showChart">aaa</Button>-->
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "index",
  components: {},
  data() {
    return {
      avatar: require('../../assets/images/home/headpic.png'),
      chartUrl: require('../../assets/images/home/chart_1.png'),
      userInfo: this.$user.getUserInfo(),
      recognitionCount: 0,
      classReportList: [],
      time: [],
      chartLabelOption: {
        show: true,
        position: 'insideBottom',
        distance: 15,
        align: 'left',
        verticalAlign: 'middle',
        rotate: 90,
        formatter: '{c}  {name|{a}}',
        fontSize: 16,
        rich: {
          name: {}
        }
      },

    }
  },
  created() {
  },
  mounted() {
    this.countEvaluationTimes();
    this.queryEvaluationReport();
  },
  methods: {
    countEvaluationTimes() {
      this.$http.countEvaluationTimes().then(res => {
        if (res.status == 1) {
          this.recognitionCount = res.data;
        }
      });
    },
    queryEvaluationReport() {
      this.$http.queryEvaluationReport(
          this.time[0] ? this.$utils.getTimeStr(this.time[0]) : '',
          this.time[1] ? this.$utils.getTimeStr(this.time[1]) : '',
      ).then(res => {
        if (res.status == 1) {
          this.classReportList = res.data;
          setTimeout(this.showChart, 200);
        }
      });
    },
    showChart() {
      for (let i = 0; i < this.classReportList.length; i++) {
        let item = this.classReportList[i];
        let aList = [];
        let bList = [];
        let xList = [];
        let option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {
            data: ['综合得分', '综合识别率']
          },
          toolbox: {
            show: true,
            orient: 'vertical',
            left: 'right',
            top: 'center',
            feature: {
              mark: {show: true},
              dataView: {show: true, readOnly: false},
              magicType: {show: true, type: ['line', 'bar', 'stack', 'tiled']},
              restore: {show: true},
              saveAsImage: {show: true}
            }
          },
          grid:{
            width: '94%',
            left: 28
          },
          xAxis: [
            {
              type: 'category',
              axisTick: {show: false},
              data: [1, 2, 3, 4, 5],
              axisLabel: {
                interval: 0,
                rotate: 30
              },
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: [
            {
              name: '综合得分',
              type: 'bar',
              barGap: 0,
              label: this.chartLabelOption,
              emphasis: {
                focus: 'series'
              },
              itemStyle: {
                color: new echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [
                      {offset: 0, color: '#4179EB'},
                      {offset: 1, color: '#46D9C6'}
                    ]
                )
              },
              data: [1, 2, 3, 4, 5]
            },
            {
              name: '综合识别率',
              type: 'bar',
              label: this.chartLabelOption,
              emphasis: {
                focus: 'series'
              },
              itemStyle: {
                color: new echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [
                      {offset: 0, color: '#FE7A89'},
                      {offset: 1, color: '#FCB971'}
                    ]
                )
              },
              data: [1, 2, 3, 4, 5]
            }
          ]
        };
        for (let j = 0; j < item.studentReports.length; j++) {
          xList.push(item.studentReports[j].studentName);
          aList.push(item.studentReports[j].comprehensiveScore);
          bList.push(item.studentReports[j].comprehensiveRecognitionRate);
        }
        option.xAxis[0].data = xList;
        option.series[0].data = aList;
        option.series[1].data = bList;
        echarts.init(document.getElementById('learnMain' + i)).setOption(option);
      }

    }
  }
}
</script>

<style scoped lang="less">
.card-title {
  position: relative;
  padding-left: 20px;

  &:before {
    position: absolute;
    top: 4px;
    left: 0px;
    content: '';
    width: 13px;
    height: 13px;
    border-radius: 6.5px;
    background-color: #ff7823;
  }
}
.card-title2 {
  position: relative;
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:before {
    position: absolute;
    top: 8px;
    left: 0px;
    content: '';
    width: 13px;
    height: 13px;
    border-radius: 6.5px;
    background-color: #ff7823;
  }
}

.home-basic {
  display: flex;
  justify-content: space-around;

  .home-basic-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .home-basic-left-img {

    }

    .home-basic-left-name {
      margin-top: 20px;
      font-weight: bold;
    }
  }

  .home-basic-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .home-basic-right-chart {
      position: relative;

      .home-basic-right-chart-img {

      }

      .home-basic-right-chart-cnt {
        position: absolute;
        top: 100px;
        left: 98px;
        color: #ff7823;
      }
    }

    .home-basic-right-label {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 16px;
      border: 1px solid #ff7823;
      width: 110px;
      height: 30px;
      border-radius: 15px;
      color: #ff7823;
    }
  }
}

.analyse-box-main {
  min-height: 296px;
  padding: 10px 20px 20px 20px;

  .analyse-box-info {
    display: flex;
    min-height: 296px;
    min-width: 650px;

    .analyse-box-info-left {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50%;

      .analyse-box-info-left-img {
        margin-top: 31px;
        width: 126px;
        height: 126px;
        //border: 3px solid #91beff;
        border: 3px solid #ff7823;
        border-radius: 100%;
        overflow: hidden;
        margin-bottom: 30px;
      }

      .analyse-box-info-left-info {
        font-size: 20px;
        letter-spacing: 1px;
      }

      .analyse-box-info-left-name {
        font-size: 24px;
        letter-spacing: 2px;
        font-weight: bold;
      }
    }

    .analyse-box-info-right {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      .analyse-box-info-right-img {
        width: 70%;
        margin-top: 30px;
      }

      .analyse-box-info-right-day {
        display: flex;
        justify-content: space-around;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 37%;
        width: 26%;
      }

      .analyse-box-info-right-desc {
        display: flex;
        justify-content: center;
        align-items: center;
        //border: 1px solid #3c7cfc;
        border: 1px solid #ff7823;
        width: 110px;
        height: 30px;
        border-radius: 15px;
        //color: #3c7cfc;
        color: #ff7823;
        cursor: pointer;
      }
    }
  }

  .analyse-box-learn {
    min-width: 650px;
    min-height: 440px;
    padding: 0 20px;

    .analyse-box-learn-up {
      display: flex;
      justify-content: space-around;
      margin: 20px 0;

      .analyse-box-learn-up-item {
        display: flex;
        align-items: center;
        width: 180px;
        height: 90px;
        box-shadow: 0 0 1px #aaa;

        .analyse-box-learn-up-item-img {
          width: 66px;
          height: 66px;
          margin: 0 11px;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 100% 100%;
        }

        .img-1 {
          background-image: url("../../assets/images/home/icon_10.png");
        }

        .img-2 {
          background-image: url("../../assets/images/home/icon_11.png");
        }

        .img-3 {
          background-image: url("../../assets/images/home/icon_12.png");
        }

        .analyse-box-learn-up-item-info {
          display: flex;
          flex-direction: column;

          .item-info-count {
            font-size: 24px;
          }

          .item-info-color1 {
            color: #3C7CFC;
          }

          .item-info-color2 {
            color: #FE7A89;
          }

          .item-info-color3 {
            color: #83D688;
          }

          .item-info-label {
            color: #999999;
          }
        }
      }
    }
  }

  .analyse-box-question {
    min-width: 880px;
    max-width: 880px;

  }

  .analyse-box-label {
    display: flex;
    flex-direction: column;
    min-width: 329px;
    margin: 26px 0 0 31px;

    .analyse-box-label-item {
      display: flex;
      align-items: center;
      height: 60px;
      width: 280px;
      padding-left: 15px;
      border-bottom: 1px dashed #DDDDDD;


      .analyse-box-label-item-index {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        background-color: #3C7CFC;
        border-radius: 6px;
        color: white;
        font-size: 20px;
      }

      .analyse-box-label-item-name {
        font-size: 16px;
        color: #424859;
        font-weight: bold;
        margin-left: 20px;
      }
    }
  }
}
</style>
